.privacy-banner {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 10%;
    background: #101010;
}

.privacy-heading {
    display: flex;
    justify-content: center;
    color: #fff;
    text-align: center;
    font-family: 'Inter';
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 5%;
    color: #0A5DFE;
}


.privacy-banner h3 {
    font-weight: 600;
    color: #0A5DFE;
}


.privacy-banner p {
    color: #fff;
    width: fit-content;
    /* body */
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.privacy-bannera,
.privacy-banner a:hover {
    width: fit-content;
    text-decoration: none !important;
}

.privacy-banner h2 {
    font-size: 26px;
    color: #0A5DFE;
}




@media (max-width:1366px) {
    .privacy-banner::before {
        height: 575px;
        width: 575px
    }

}

@media (max-width:920px) {
    .privacy-banner {
        margin-top: 30%;
    }
}

@media (max-width: 650px) {

    .privacy-banner {
        margin-top: 30%;
    }
}

@media (max-width:640px) {
    .privacy-banner {
        margin-top: 30%;
    }
}

@media (max-width: 420px) {
    .privacy-banner {
        margin-top: 55%;
    }
}

@media (max-width: 375px) {
    .privacy-banner {
        margin-top: 55%;
    }
}

@media (max-width: 360px) {
    .privacy-banner {
        margin-top: 55%;
    }
}

@media (max-width: 300px) {
    .privacy-banner {
        margin-top: 80%;
    }

}