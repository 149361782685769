.navbar {
    background: #101010;
    padding: 2%;
}

.nav-item {
    z-index: 5;
}

.navbar-brand {
    z-index: 5;
}

.navbar-collapse {
    display: flex;
    justify-content: flex-end;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

a {
    text-decoration: none;
}

.navbar-button:hover {
    color: #ddd;
}

.navbar-nav {
    align-items: center;
    display: flex;
    gap: 2rem;
}

.nav-item {
    color: var(--White, var(--White, #FFF));
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media(max-width: 950px) {
    .navbar-button {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 16px;
    }
}